import React from "react";
import { Redirect } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { GelButton } from "@tal-gel/components";

/**
 * Was originally being used with Microsoft Auth, however after migrating to Okta, it is no longer needed.
 * Waiting for permission to delete...just in case
 */
const Login = (): JSX.Element => {
  const { oktaAuth, authState } = useOktaAuth();

  if (!authState) return <div>loading... </div>;

  return authState.isAuthenticated ? (
    <Redirect to={{ pathname: "/me/form" }} />
  ) : (
    <GelButton
      onClick={async () => {
        await oktaAuth.signInWithRedirect();
      }}
    >
      Sign In
    </GelButton>
  );
};
export default Login;
