/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createClient } from "contentful";
export default class ContentfulService {
  getClient = async () => {
    const client = await createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE || "",
      environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID || "",
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || "",
    });
    return client;
  };

  getContentType = async (contentType: string, slug: string) => {
    const client = await this.getClient();
    const entry = await client.getEntries({
      include: 2,
      content_type: contentType,
      "fields.slug[match]": slug,
    });
    return entry;
  };
}
