export const choices = [
  { value: 1, name: "1" },
  { value: 2, name: "2" },
  { value: 3, name: "3" },
  { value: 4, name: "4" },
  { value: 5, name: "5" },
  { value: 6, name: "6" },
  { value: 7, name: "7" },
  { value: 8, name: "8" },
  { value: 9, name: "9" },
  { value: 10, name: "10" },
];

/**
 * this export must have its keys match
 * @type {CustomerQuestionsResult} is dependent on this for its keys, please makesure questions match that list
 * @see {CustomerQuestionsResultKeys} for the array which is used to iterate through the objects
 */
export const customerQuestions = {
  homeActivities:
    '" In the past week, how do you rate your ability to complete activities at home such as cleaning, tidying, cooking or looking after any dependents? "',
  leisureActivities:
    '" In the past week, how do you rate your ability to participate in leisure activities such as walking, hobbies, gardening etc. "',
  socialActivities:
    '" In the past week, how do you rate your ability to socialise with others for example going out to a cafe or restaurant with others, home entertaining "',
  conditionActivities:
    '" How has your condition affected your ability to perform these activities? What activities are you trying to perform? "',
  improveActivities:
    '" If you could improve one of these activities we\'ve discussed by 1-2 points, which would it be? What would help you to reach a higher score? "',
  professionalSupport: '" What treatment or support are you receiving. How is it helping? "',
  personalSupport:
    '" Is there anything else you are doing to help yourself such as home exercises, hobbies, online self help? If so, what sort of things are you currently doing? "',
  rtw: '" Have you spoken with your Doctor or Employer about returning to work in the future? "',
  rtwFollowUp: '" Do you think you will return back to work in the future? "',
  rtwConfidence:
    '" On a scale of 1 to 10, How confident are you in returning to work? 1  means you have very low confidence and 10 means you are highly confident. "',
  rtwTimeFrame: '" When do you expect to RTW? "',
  rtwLimitations: '" What are the main factors limiting your ability to return to work? "',
  finances:
    '" On a scale of 1 to 10, How do you rate your ability to manage your finances since being off work for example paying bills on time, having enough to pay for treatment. 1  means you have very low confidence in your ability to manage your finances and 10 means you are highly confident. "',
  friendsFamilySupport: '" Do you have any family and friends supporting you more as a result of your medical condition? "',
  friendsFamilyFollowUp: '" What support are they giving you and how are they coping? "',
  requiredQuestionMessage: "This question is required",
};
