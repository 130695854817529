import { SupportGroup } from "../models/supportTypes";
import { syncObject } from "../models/adobeDLTypes";
import { formatSupportOptionsForAdobe, getAdobeFundName } from "./helperService";

// using window object from browser but modified
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
declare let window: any;

const startCTA = "start";
const viewSupportOptionsCTA = "view support options";
const copyToFileNoteCTA = "copy to file note";
const sendToEmailCTA = "send to email";

const _event = "Health Support Indicator CTA Click";

const syncDataLayerToAdobe = (sycObject: syncObject) => {
  window.appEventData.push(sycObject);
};

export const syncStart = (fund: string): void => {
  const syncStart: syncObject = {
    event: _event,
    hsi: { cta: startCTA, fund: getAdobeFundName(fund) },
  };
  syncDataLayerToAdobe(syncStart);
};

export const syncViewSupportOptions = (supportGroups: SupportGroup[], stream: string): void => {
  const syncStart: syncObject = {
    event: _event,
    hsi: { cta: viewSupportOptionsCTA, supportOptions: formatSupportOptionsForAdobe(supportGroups), stream: stream },
  };
  syncDataLayerToAdobe(syncStart);
};

export const syncSupportOptionsOnCopyToFileNote = (supportGroups: SupportGroup[]): void => {
  const syncStart: syncObject = {
    event: _event,
    hsi: { cta: copyToFileNoteCTA, supportOptions: formatSupportOptionsForAdobe(supportGroups) },
  };
  syncDataLayerToAdobe(syncStart);
};

export const syncSupportOptionsOnSendToEmail = (supportGroups: SupportGroup[]): void => {
  const syncStart: syncObject = {
    event: _event,
    hsi: { cta: sendToEmailCTA, supportOptions: formatSupportOptionsForAdobe(supportGroups) },
  };
  syncDataLayerToAdobe(syncStart);
};
