import { Entry, EntryCollection } from "contentful";
import { CallerType, FriendsFamilySupport, Funds, ClaimStatusDescriptions, Condition, Employment, ClaimType } from "../enums/answers";
import { ClaimDetailsResult } from "../interfaces/ClaimDetailsResult";
import { CustomerQuestionsResult } from "../interfaces/CustomerQuestionsResult";
import { SupportGroupsResult, SupportGroup, SupportService, ContentfulSupportGroup, ContenfulSupportService } from "../models/supportTypes";
import ContentfulService from "./contentfulService";
import ContentTypes from "./contentTypes";

export class SupportGroupService {
  // stores the collection of groups while running
  private supportGroupsResult: SupportGroupsResult;
  private contentfulFetchList: Array<string>;

  constructor() {
    this.supportGroupsResult = { supportGroups: new Array<SupportGroup>() } as SupportGroupsResult;
    this.contentfulFetchList = [];
  }

  /**
   * this private static function replaces an exported function and now properly encapsulates all of its logic
   * @param claimDetailsResult
   * @param customerQuestionsResult
   * @returns returns a object with support groups contained within
   */
  public static async getSupportOptionsBasedOnAnswers(
    claimDetailsResult: ClaimDetailsResult,
    customerQuestionsResult: CustomerQuestionsResult,
  ): Promise<SupportGroupsResult> {
    const service = new SupportGroupService();

    if (claimDetailsResult.fund === Funds.AwareSuper) {
      service.contentfulFetchList.push("anyClaimType-AwareSuper");
    }

    if (claimDetailsResult.caller === CallerType.CARER) {
      service.contentfulFetchList.push("anyclaimtype-carer");
    } else if (claimDetailsResult.caller === CallerType.CLAIMANT) {
      service.ProcessClaimantCaller(claimDetailsResult, customerQuestionsResult);
    }

    await service.fetchContentfulSupportGroups();
    return service.supportGroupsResult;
  }

  /**
   * Processes the support group to fetch when the caller is the claimant
   * @param claimDetailsResult the claim form result
   * @param customerQuestionsResult the customer questions form result
   */
  private ProcessClaimantCaller(claimDetailsResult: ClaimDetailsResult, customerQuestionsResult: CustomerQuestionsResult) {
    //push TDP by default for claimants
    this.contentfulFetchList.push("tdp-claimant");

    if (customerQuestionsResult.friendsFamilySupport === FriendsFamilySupport.YES) {
      this.contentfulFetchList.push("claimant-withcarer");
    }
    if (claimDetailsResult.claimType === ClaimType.IP && claimDetailsResult.claimStatus === ClaimStatusDescriptions.Admitted) {
      this.processIpAdmittedClaimant(claimDetailsResult, customerQuestionsResult);
    }
  }

  /**
   * Processes when the claimant has rung in and admitted and has income protection
   * @param claimDetailsResult the claim form result
   * @param customerQuestionsResult the customer questions form result
   */
  private processIpAdmittedClaimant(claimDetailsResult: ClaimDetailsResult, customerQuestionsResult: CustomerQuestionsResult): void {
    if (claimDetailsResult.fund === Funds.AustralianSuper) {
      this.contentfulFetchList.push("ip-australiansuper");
      return;
    }

    switch (claimDetailsResult.condition) {
      case Condition.MentalAndBehaviouralDisorders:
        this.contentfulFetchList.push("ip-mental-claimant");
        break;
      case Condition.MusculoskeletalInclInjuriesAndFractures:
        this.contentfulFetchList.push("ip-muscle");
        break;
      case Condition.Cancer:
        this.contentfulFetchList.push("ip-cancer");
        break;
      case Condition.Other:
        this.contentfulFetchList.push("ip-other");
        break;
    }

    const meanActivity =
      (customerQuestionsResult.homeActivities + customerQuestionsResult.leisureActivities + customerQuestionsResult.socialActivities) / 3;

    if (claimDetailsResult.condition != Condition.MentalAndBehaviouralDisorders && meanActivity < 6) {
      this.contentfulFetchList.push("ip-notmental");
    }

    switch (claimDetailsResult.jobAvailable) {
      case Employment.YesSelfEmployed: {
        this.contentfulFetchList.push("ip-selfemployed");
        break;
      }
      case Employment.No: {
        this.contentfulFetchList.push("ip-unemployed");
        break;
      }
      case Employment.YesEmployed:
      default: {
        this.contentfulFetchList.push("ip-employed");
      }
    }

    if (customerQuestionsResult.finances < 8) {
      this.contentfulFetchList.push("ip-any");
    }
  }

  /**
   * Fetches all the slugs collected in the contentful fetchlist
   * @returns a promise which will give a message if it fails
   */
  private async fetchContentfulSupportGroups(): Promise<void> {
    try {
      const contentfulService = new ContentfulService();

      for await (const supportGroup of this.contentfulFetchList) {
        const entry: EntryCollection<ContentfulSupportGroup> = (await contentfulService.getContentType(
          ContentTypes.SUPPORT_GROUP,
          supportGroup,
        )) as EntryCollection<ContentfulSupportGroup>;
        if (entry.errors != null || entry.items?.length === 0) {
          return Promise.reject(`Support Group '${supportGroup}' was not found.`);
        }
        this.supportGroupsResult.supportGroups.push(this.mapSupportGroup(entry.items[0]));
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Maps a contentful object back into a DTO object
   * @param supportGroup an object from contentful containing all the data
   * @returns {SupportGroup} stripped of all the contentful meta data
   */
  private mapSupportGroup(supportGroup: Entry<ContentfulSupportGroup>): SupportGroup {
    return {
      id: supportGroup.sys.id,
      title: supportGroup.fields.title,
      description: supportGroup.fields.description,
      isArchived: supportGroup.fields.isArchived,
      slug: supportGroup.fields.slug,
      supportServices: supportGroup.fields.supportServices ? this.mapSupportService(supportGroup.fields.supportServices) : [],
    };
  }

  /**
   * maps the support services from contentful back into a DTO object
   * @param SupportServices an object from contentful containing the data to map into a support service
   * @returns {Array<SupportService>} an array of support service objects stripped of all contentful meta data
   */
  private mapSupportService(SupportServices: Array<Entry<ContenfulSupportService>>): Array<SupportService> {
    return SupportServices.map(supportService => ({
      id: supportService.sys.id,
      title: supportService.fields.title,
      description: supportService.fields.description,
      shortDescription: supportService.fields.shortDescription,
      link: supportService.fields.link,
      isArchived: supportService.fields.isArchived,
      slug: supportService.fields.slug,
      isSelected: false,
    }));
  }
}
