import React from "react";
import { GelRowLayout, GelBoxLayout, GelHeading6 } from "@tal-gel/components";
import { SupportStreamCollection } from "../../models/supportStreams";
import "./supportStream.css";
import { SupportStreamItem } from "../../interfaces/SupportStream";

const SupportStream: React.FC<SupportStreamProp> = SupportStreamLabel => {
  return (
    <GelRowLayout gutter="small">
      <GelBoxLayout space="auto" distribution="start">
        <GelHeading6>Support Stream: </GelHeading6>
        {SupportStreamCollection.map((supportStream, index) => {
          const supportStreamItem: SupportStreamItem = supportStream.obj as SupportStreamItem;
          return supportStream.value === SupportStreamLabel.Label ? (
            <div className="chip" key={index} style={{ backgroundColor: supportStreamItem.color }}>
              <div className="chip-content">{supportStreamItem.text}</div>
            </div>
          ) : (
            <GelHeading6 key={index} className={"label"}>
              {supportStreamItem.text}
            </GelHeading6>
          );
        })}
      </GelBoxLayout>
    </GelRowLayout>
  );
};

export default SupportStream;

export interface SupportStreamProp {
  Label: string;
}
