import React, { useState } from "react";
import {
  GelParagraph,
  GelForm,
  GelFormField,
  GelTextInput,
  GelSelect,
  GelColumnLayout,
  GelAccordion,
  GelRowLayout,
  GelButton,
  GelBoxLayout,
  GelIcon,
  GelModal,
  GelHeading6,
  GelHeading4,
  useGelFormData,
} from "@tal-gel/components";
import "./claimDetails.css";
import { formatDate } from "../../services/helperService";
import { claimStatus, claimTypes, employment, fund, caller, condition } from "../../models/claimDetailItems";
import { ClaimsFunctionProp } from "../../interfaces/props/ClaimsFunctionProp";

const ClaimDetails: React.FC<ClaimsFunctionProp> = props => {
  const [enableEditButton, setEnableEditButton] = useState<boolean>(false);
  const [disableForm, setDisableForm] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { formData, onFormDataChange } = useGelFormData({
    selectedDate: formatDate(Date.now()),
    claimNumber: null,
    claimType: null,
    claimStatus: "",
    condition: "",
    fund: "",
    jobAvailable: "",
    caller: "",
  });

  //Function to update values in Parent Component
  const Submit = () => {
    props.submit(
      {
        date: formData.Date ?? "",
        caller: formData.caller ?? "",
        claimNumber: formData.claimNumber ?? "",
        claimStatus: formData.claimStatus ?? "",
        claimType: formData.claimType ?? "",
        condition: formData.condition ?? "",
        fund: formData.fund ?? "",
        jobAvailable: formData.jobAvailable ?? "",
      },
      true,
    );
    setEnableEditButton(true);
    setDisableForm(true);
  };

  const Edit = () => {
    setEnableEditButton(false);
    setDisableForm(false);
    props.submit(
      {
        date: "",
        caller: "",
        claimNumber: "",
        claimStatus: "",
        claimType: "",
        condition: "",
        fund: "",
        jobAvailable: "",
      },
      false,
    );
    setOpenModal(false);
  };

  return (
    <GelAccordion title={<GelHeading4>1: Claim Information </GelHeading4>} defaultExpand minContentHeight={370}>
      <div className={"background"}>
        <GelBoxLayout space="auto" distribution="start">
          <GelIcon name="AlertCircle" width={24} color={"#fff"} />
          <GelParagraph>
            <span className={"whiteText"}>Claim information must be completed before starting the questionnaire</span>
          </GelParagraph>
        </GelBoxLayout>
      </div>
      <br />
      <GelForm id={"form"} labelWidth={180} fieldGutter={30} labelAtTop preventSubmitOnInvalid disabled={disableForm}>
        <GelRowLayout gutter={"medium"}>
          <GelColumnLayout distribution="1/3 1/3 1/3">
            {/* TODO: New place for Date Completed field to be defined            
            <GelFormField label="Date Completed">
              <GelDateInput name="dateCompleted" onChange={UpdateDate} defaultValue={selectedDate} required requiredErrorMsg="Date is required" width={250} />
            </GelFormField> */}
            <GelFormField label="Claim No">
              <GelTextInput name="claimNumber" required requiredErrorMsg="Claim No. is required" width={250} onChange={onFormDataChange} />
            </GelFormField>
            <GelFormField label="Claim Type">
              <GelSelect
                name="claimType"
                required
                requiredErrorMsg="Claim Type is required"
                placeholder="Select one"
                options={claimTypes.map(item => ({ value: item.value, label: item.name }))}
                width={250}
                onChange={onFormDataChange}
              />
            </GelFormField>
            <GelFormField label="Claim Status">
              <GelSelect
                name="claimStatus"
                required
                requiredErrorMsg="Claim Status is required"
                placeholder="Select one"
                options={claimStatus.map(item => ({ value: item.value, label: item.name }))}
                width={250}
                onChange={onFormDataChange}
              />
            </GelFormField>
          </GelColumnLayout>
          <GelColumnLayout distribution="1/3 1/3 1/3">
            <GelFormField label="Condition">
              <GelSelect
                name="condition"
                required
                requiredErrorMsg="Condition is required"
                placeholder="Select one"
                options={condition.map(item => ({ value: item.value, label: item.name }))}
                width={250}
                onChange={onFormDataChange}
              />
            </GelFormField>
            <GelFormField label="Fund">
              <GelSelect
                name="fund"
                required
                requiredErrorMsg="Fund is required"
                placeholder="Select one"
                options={fund.map(item => ({ value: item.value, label: item.name }))}
                width={250}
                onChange={onFormDataChange}
              />
            </GelFormField>
            <GelFormField label="Job Available?">
              <GelSelect
                name="jobAvailable"
                placeholder="Select one"
                options={employment.map(item => ({ value: item.value, label: item.name }))}
                width={250}
                onChange={onFormDataChange}
              />
            </GelFormField>
          </GelColumnLayout>
          <GelColumnLayout distribution="1/3 1/3 1/3">
            <GelFormField label="Caller">
              <GelSelect
                name="caller"
                required
                requiredErrorMsg="Caller details required"
                placeholder="Select one"
                options={caller.map(item => ({ value: item.value, label: item.name }))}
                width={250}
                onChange={onFormDataChange}
              />
            </GelFormField>
          </GelColumnLayout>
          <GelBoxLayout space="auto" distribution="start">
            <GelFormField>
              <GelButton name="startButton" submit primary medium onClick={Submit}>
                Start
              </GelButton>
            </GelFormField>
            <GelButton disabled={!enableEditButton} primary medium onClick={() => setOpenModal(true)}>
              Edit Claim Information
            </GelButton>
          </GelBoxLayout>
          {/* Modal acts as prompt/confirmation to clear customer responses when edit claim information event is triggered */}
          <GelModal
            width={600}
            open={openModal}
            onClose={() => setOpenModal(false)}
            title={<GelHeading6 as="h2">Are you sure you want to edit the claim information data?</GelHeading6>}
            action={
              <GelBoxLayout gap="small" distribution="start">
                <GelButton secondary medium width={90} onClick={() => setOpenModal(false)}>
                  CANCEL
                </GelButton>
                <GelButton primary medium width={300} onClick={Edit}>
                  YES, CLEAR CUSTOMER ANSWERS
                </GelButton>
              </GelBoxLayout>
            }
          >
            <GelParagraph>If you proceed, you will lose all of the below customer answers.</GelParagraph>
            <GelParagraph>This action can not be undone.</GelParagraph>
          </GelModal>
        </GelRowLayout>
      </GelForm>
    </GelAccordion>
  );
};

export default ClaimDetails;
