import React, { useState, useRef } from "react";
import {
  GelBoxLayout,
  GelButton,
  GelContainerLite,
  GelHeading4,
  GelParagraph,
  GelRowLayout,
  GelTooltip,
  GelLabel,
} from "@tal-gel/components";
import "./form.css";
import ClaimDetails from "../../components/claimDetails/claimDetails";
import CustomerQuestions from "../../components/customerQuestions/customerQuestions";
import SupportOptions from "../../components/supportOptions/supportOptions";
import { ClaimDetailsResult } from "../../interfaces/ClaimDetailsResult";
import { CustomerQuestionsResult } from "../../interfaces/CustomerQuestionsResult";
import { SupportGroup } from "../../models/supportTypes";
import { SupportGroupService } from "../../services/supportGroupService";
import { getSupportStreamLabels } from "../../services/supportStreamService";
import SupportStream from "../../components/supportStream/supportStream";
import { CopyToClipboard, CopyToEmail } from "../../services/copyToClipboardService";
import { CallerType } from "../../enums/answers";
import quote from "../../assets/quote.svg";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { getGelTokens } from "@tal-gel/theming";
import {
  syncStart,
  syncViewSupportOptions,
  syncSupportOptionsOnCopyToFileNote,
  syncSupportOptionsOnSendToEmail,
} from "../../services/adobeDataLayer";

const ClaimantForm = (): JSX.Element => {
  const [claimDetailsResult, setClaimDetailsResult] = useState<ClaimDetailsResult>({
    date: "",
    claimNumber: "",
    claimType: "",
    claimStatus: "",
    condition: "",
    fund: "",
    jobAvailable: "",
    caller: "",
  });
  const [validClaimState, setValidClaimState] = useState<boolean>(false);
  const [isCarer, setCarerState] = useState<boolean>(false);
  const [supportGroups, setSupportGroups] = useState<SupportGroup[]>();
  const [enableSupportButton, setEnableSupportButton] = useState<boolean | undefined>(undefined);
  const [enableEditAnswerButton, setEnableEditAnswerButton] = useState<boolean>(false);
  const [supportStreamLabel, setSupportStreamLabel] = useState<string>("");
  const [customerQuestionsResult, setCustomerQuestionsResult] = useState<CustomerQuestionsResult | null>(null);
  const [enableCopyToFilenoteButton, setEnableCopyToFilenoteButton] = useState<boolean>(false);
  const [open, setAlertOpen] = useState<boolean>(false);
  const [copyState, setCopyState] = useState<boolean>(false);

  const custQuestionsRef = useRef(null);
  const supOptionsRef = useRef(null);
  const scrollToCustomerQuetions = () => scrollToDiv(custQuestionsRef);
  const scrollToSupportOptions = () => scrollToDiv(supOptionsRef);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollToDiv = (ref: React.MutableRefObject<any>) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const updateClaimDetails = (claimDetailsResult: ClaimDetailsResult, isValid: boolean) => {
    setCarerState(claimDetailsResult.caller == CallerType.CARER);
    setClaimDetailsResult(claimDetailsResult);
    setValidClaimState(isValid);
    setEnableSupportButton(undefined);
    setEnableCopyToFilenoteButton(claimDetailsResult.caller == CallerType.CARER);
    setEnableEditAnswerButton(false);
    setSupportGroups([]);
    setSupportStreamLabel("");
    isValid &&
      setTimeout(() => {
        syncStart(claimDetailsResult.fund);
        scrollToCustomerQuetions();
      }, 500);
  };

  const viewSupportOptions = async (customerQuestionsResult: CustomerQuestionsResult) => {
    setCustomerQuestionsResult(customerQuestionsResult);
    const supportGroupsResult = await SupportGroupService.getSupportOptionsBasedOnAnswers(claimDetailsResult, customerQuestionsResult);
    const supportStreamLabel = getSupportStreamLabels(customerQuestionsResult);
    const uniqueSupportGroups = removeSupportGroupDuplicates(supportGroupsResult.supportGroups);
    setSupportStreamLabel(supportStreamLabel && supportStreamLabel[0] ? supportStreamLabel[0] : "");
    setSupportGroups(uniqueSupportGroups);
    setEnableSupportButton(false);
    setEnableEditAnswerButton(true);
    setEnableCopyToFilenoteButton(true);
    syncViewSupportOptions(supportGroupsResult.supportGroups, supportStreamLabel[0]);
    scrollToSupportOptions();
  };

  const removeSupportGroupDuplicates = (supportGroups: SupportGroup[]): SupportGroup[] => {
    const newGroups: SupportGroup[] = [];
    supportGroups.forEach((group: SupportGroup) => {
      // If the newGroups array contains a SupportGroup with the same title then we don't add it to the array
      if (!newGroups.some((newGroup: SupportGroup) => newGroup.title === group.title)) {
        newGroups.push(group);
      }
    });
    return newGroups;
  };

  const editAnswers = () => {
    setEnableEditAnswerButton(false);
    setEnableSupportButton(true);
    setSupportGroups([]);
    setSupportStreamLabel("");
  };

  const setCopyToFileNote = async () => {
    const resp = await CopyToClipboard(
      claimDetailsResult,
      customerQuestionsResult as CustomerQuestionsResult,
      supportStreamLabel,
      supportGroups as SupportGroup[],
    );
    setCopyState(resp);
    syncSupportOptionsOnCopyToFileNote(supportGroups as SupportGroup[]);
    setAlertOpen(true);
  };

  const setCopyToEmail = async () => {
    const resp = await CopyToEmail(
      claimDetailsResult,
      customerQuestionsResult as CustomerQuestionsResult,
      supportStreamLabel,
      supportGroups as SupportGroup[],
    );
    setCopyState(resp);
    syncSupportOptionsOnSendToEmail(supportGroups as SupportGroup[]);
    setAlertOpen(true);
  };

  return (
    <GelContainerLite gutter={"large"}>
      <GelRowLayout gutter="large">
        <ClaimDetails submit={updateClaimDetails} />
        <div ref={custQuestionsRef}>
          {validClaimState && !isCarer && (
            <>
              <GelHeading4 className="titleText">2: Customer Questions</GelHeading4>
              <CustomerQuestions
                selectedClaimType={claimDetailsResult.claimType}
                disabled={enableEditAnswerButton}
                viewSupportOptions={viewSupportOptions}
                editAnswers={editAnswers}
                enableEditAnswerButton={enableEditAnswerButton}
                enableSupportButton={enableSupportButton}
              />
            </>
          )}

          {isCarer && (
            <>
              <GelHeading4 className="titleText">2: Customer Questions</GelHeading4>
              <GelRowLayout gutter="medium" alignment="center">
                <GelParagraph>
                  <span className="heading">Customer Questions are unavailable</span>
                </GelParagraph>
                <GelParagraph>
                  <span className="subHeading">Carer does not need to complete customer questions.</span>
                </GelParagraph>
              </GelRowLayout>
            </>
          )}
        </div>

        {validClaimState && isCarer && (
          <GelBoxLayout space="auto" distribution="start">
            <GelButton
              disabled={enableSupportButton === undefined ? false : !enableSupportButton}
              primary
              medium
              onClick={viewSupportOptions}
            >
              VIEW SUPPORT OPTIONS
            </GelButton>
          </GelBoxLayout>
        )}

        <div ref={supOptionsRef}>
          {enableEditAnswerButton && (
            <>
              <GelHeading4 className="titleText">3: Support Options</GelHeading4>
              {supportStreamLabel && supportStreamLabel.length > 0 ? <SupportStream Label={supportStreamLabel} /> : null}
              {supportGroups && supportGroups?.length > 0 && (
                <div className={"greenQuote"}>
                  <GelBoxLayout space={["auto", 1]} distribution={"start"}>
                    <img alt="quote mark" src={quote} className={"quote"} />
                    <GelRowLayout gutter="medium">
                      <GelParagraph>
                        <span className={"blackText"}>
                          Thank you for your time and patience throughout the call today. After we finish this call, I will send you some
                          information about support services by email. Can you confirm your email address for me? [note email address]
                        </span>
                        <span className={"blackText marginT20DisplayBlock"}>
                          If you do have any questions after this call today or about any of the information included in the email please
                          feel free to contact me directly
                        </span>
                      </GelParagraph>
                    </GelRowLayout>
                  </GelBoxLayout>
                </div>
              )}
              {supportGroups && supportGroups?.length > 0 ? <SupportOptions supportGroups={supportGroups} /> : null}
              {supportGroups && supportGroups?.length > 0 && (
                <>
                  <GelLabel>
                    <div className="promptLabel">Prompt if claimant is interested:</div>
                  </GelLabel>
                  <div className={"greenQuote"}>
                    <GelBoxLayout space={["auto", 1]} distribution={"start"}>
                      <img alt="quote mark" src={quote} className={"quote"} />
                      <GelRowLayout gutter="medium">
                        <GelParagraph>
                          <span className={"blackText"}>
                            Thank you for your time and patience during our call today. After the call, I will send you an email about
                            support services to explore in your own time. If you have any questions please contact me.
                          </span>
                        </GelParagraph>
                      </GelRowLayout>
                    </GelBoxLayout>
                  </div>
                  <GelBoxLayout space={"auto"}>
                    <div className="promptLine" />
                    <div>Or</div>
                    <div className="promptLine" />
                  </GelBoxLayout>
                  <GelLabel>
                    <div className="promptLabel">Prompt if claimant is not interested:</div>
                  </GelLabel>
                  <div className={"greenQuote"}>
                    <GelBoxLayout space={["auto", 1]} distribution={"start"}>
                      <img alt="quote mark" src={quote} className={"quote"} />
                      <GelRowLayout gutter="medium">
                        <GelParagraph>
                          <span className={"blackText"}>
                            That&apos;s Ok, if your situation changes and you want to discuss how we may support you in the future, please
                            let me know.
                          </span>
                        </GelParagraph>
                      </GelRowLayout>
                    </GelBoxLayout>
                  </div>
                  {validClaimState && (
                    <>
                      <Snackbar
                        open={open}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={6000}
                        onClose={() => setAlertOpen(false)}
                      >
                        <SnackbarContent
                          style={
                            copyState
                              ? { backgroundColor: getGelTokens().global.themeColorSuccess }
                              : { backgroundColor: getGelTokens().global.themeColorWarning }
                          }
                          message={<span id="client-snackbar">{copyState ? "Action successful!" : "Action failed!"}</span>}
                        />
                      </Snackbar>
                      <div className="marginT50">
                        <GelBoxLayout space="auto" distribution="start">
                          <GelButton secondary medium onClick={setCopyToFileNote} disabled={!enableCopyToFilenoteButton}>
                            COPY TO FILE NOTE
                          </GelButton>
                          <GelTooltip bottomLeft width={400}>
                            Copy and paste questionnaire and recommendation to your preferred application
                          </GelTooltip>
                          <GelButton secondary medium onClick={setCopyToEmail} disabled={!enableCopyToFilenoteButton}>
                            SEND TO EMAIL
                          </GelButton>
                          <GelTooltip bottomLeft width={400}>
                            Create new email with recommendation already pasted into the email body
                          </GelTooltip>
                        </GelBoxLayout>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </GelRowLayout>
    </GelContainerLite>
  );
};

export default ClaimantForm;
