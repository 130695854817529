export const claimTypes = [
  { value: "IP", name: "Income Protection" },
  { value: "TPD", name: "Total Permanent Disability" },
];
export const claimStatus = [
  { value: "notified", name: "Notified" },
  { value: "received", name: "Received" },
  { value: "admitted", name: "Admitted" },
  { value: "closed", name: "Closed" },
];
export const condition = [
  { value: "1", name: "Cancer" },
  { value: "2", name: "Mental and behavioural disorders" },
  { value: "3", name: "Musculoskeletal incl Injuries and Fractures" },
  { value: "4", name: "Other" },
];
export const fund = [
  { value: "1", name: "Aware Super" },
  { value: "2", name: "Australian Super" },
  { value: "3", name: "BHP" },
  { value: "4", name: "Blackmores Limited" },
  { value: "5", name: "Catholic super" },
  { value: "6", name: "Cbus" },
  { value: "7", name: "Combined fund" },
  { value: "8", name: "Diageo Australia Limited" },
  { value: "9", name: "Equity trustees" },
  { value: "10", name: "IBM Australia" },
  { value: "11", name: "IOOF" },
  { value: "12", name: "Mine wealth + wellbeing" },
  { value: "13", name: "NGS Super" },
  { value: "14", name: "Norton Rose Fulbright Australia Partners" },
  { value: "15", name: "NSW Police Blue Ribbon" },
  { value: "16", name: "Prime Super" },
  { value: "17", name: "Rest Super" },
  { value: "18", name: "Rssmt" },
  { value: "19", name: "Russell Super" },
  { value: "20", name: "Tal - other" },
  { value: "21", name: "Tal Corporate" },
  { value: "22", name: "Telstrasuper" },
  { value: "23", name: "The Executive Superannuation Fund" },
  { value: "24", name: "TWU Superannuation Fund" },
  { value: "25", name: "Unisuper" },
  { value: "26", name: "WA super" },
  { value: "27", name: "Downer Edi Mining" },
  { value: "28", name: "LGIA Super" },
];
export const employment = [
  { value: "1", name: "Yes - Employed" },
  { value: "2", name: "Yes - Self Employed" },
  { value: "3", name: "No" },
];

export const caller = [
  { value: 1, name: "Claimant" },
  { value: 2, name: "Carer" },
];
