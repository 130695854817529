import React, { useEffect, useState } from "react";
import "./header.css";
import { GelContainerLite, GelBoxLayout } from "@tal-gel/components";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { useOktaAuth } from "@okta/okta-react";

const Header = (): JSX.Element => {
  const [userInitials, setUserInitials] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const { oktaAuth, authState } = useOktaAuth();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth.getUser().then(user => {
        const initials = user.name?.match(/\b\w/g) || [];
        const init = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
        setUserInitials(init);
      });
    }
  }, [authState]);
  return (
    <GelContainerLite
      gutter="medium"
      style={{
        borderBottom: "solid 1px #ccc",
      }}
    >
      <GelBoxLayout space={"auto"} distribution={"spaceBetween"}>
        <GelBoxLayout space="auto" distribution="start" gap="large">
          <a className={"icon-link"} href={"/"}>
            <GelBoxLayout space="auto" distribution="start" gap="small">
              <img src={process.env.PUBLIC_URL + "/TAL-header.svg"} width="80px" alt={"TAL Logo"} />
              <strong className="title">
                SUPPORT
                <br />
                INDICATOR
              </strong>
            </GelBoxLayout>
          </a>
        </GelBoxLayout>
        <GelBoxLayout space={"auto"}>
          <div className="nav-menu">
            <Avatar className="avatar" onClick={handleClick}>
              {userInitials}
            </Avatar>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuList>
                <MenuItem
                  onClick={() => {
                    history.push("/me/sign-out");
                  }}
                >
                  Log out
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </GelBoxLayout>
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export default Header;
