import React, { useEffect } from "react";
import { GelButton, GelHeading1 } from "@tal-gel/components";
import { useHistory } from "react-router-dom";
import "./home.css";
import { useOktaAuth } from "@okta/okta-react";

const Home = (): JSX.Element => {
  const { oktaAuth, authState } = useOktaAuth();
  const history = useHistory();

  useEffect(() => {
    if (authState) {
      authState.isAuthenticated ? history.push("/me/form") : oktaAuth.signInWithRedirect();
    }
  }, [authState]);

  return (
    <div className="main-container">
      <GelHeading1>Welcome to Support Indicator</GelHeading1>
      <GelButton
        primary
        large
        onClick={async () => {
          await oktaAuth.signInWithRedirect();
        }}
      >
        Sign In
      </GelButton>
    </div>
  );
};

export default Home;
