import React from "react";
import { GelButton, GelHeading1 } from "@tal-gel/components";
import "./signout.css";
import { useOktaAuth } from "@okta/okta-react";

const SignOut = (): JSX.Element => {
  const { authState, oktaAuth } = useOktaAuth();

  if (!authState) {
    return <div>loading...</div>;
  }

  return (
    <div className="main-container">
      <GelHeading1>Signed Out</GelHeading1>
      <GelButton
        primary
        large
        onClick={async () => {
          await oktaAuth.signOut();
          oktaAuth.tokenManager.clear();
        }}
      >
        Sign Out
      </GelButton>
    </div>
  );
};

export default SignOut;
