import React from "react";
import { GelFormField, GelRadioGroup, GelRadio } from "@tal-gel/components";
import { RadioButttonListProp } from "../../interfaces/props/RadioButtonListProp";

import "../customerQuestions/customerQuestions.css";
import { customerQuestions } from "../../models/customerQuestionItems";

const FormRadioButtonList: React.FC<RadioButttonListProp> = props => {
  //Function to update values in Parent Component
  const UpdateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.update) {
      props.update(e.target.value);
    }
  };
  return (
    <GelFormField label={props.label} width={450}>
      <GelRadioGroup
        required
        requiredErrorMsg={customerQuestions.requiredQuestionMessage}
        disabled={props.disabled}
        vertical
        gap="small"
        name={props.fieldName}
        value={props.formData[props.fieldName]} // has to be the same as `name`
        onChange={(e: any) => {
          UpdateValue(e);
          props.onFormDataChange(e);
        }}
      >
        {props.choices.map((choice, index) => {
          return (
            <GelRadio key={index} value={choice.value}>
              {choice.text}
            </GelRadio>
          );
        })}
      </GelRadioGroup>
    </GelFormField>
  );
};

export default FormRadioButtonList;
