import React from "react";
import { useHistory } from "react-router-dom";

import "./App.css";

import AuthLayout from "./layouts/authLayout";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { oktaAuthConfig } from "./setupOkta";

const oktaAuth = new OktaAuth(oktaAuthConfig);
setInterval(oktaAuth.session.refresh, 60 * 60 * 1000);

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri ?? window.location.origin, window.location.origin));
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AuthLayout />
    </Security>
  );
}

export default App;
