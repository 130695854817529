import React from "react";
import { GelParagraph, GelBoxLayout, GelRadioGroup, GelRadio, GelFormField } from "@tal-gel/components";
import "./formSlider.css";
import { SliderFunctionProp } from "../../interfaces/props/SliderFunctionProp";
import { choices, customerQuestions } from "../../models/customerQuestionItems";

const FormSlider: React.FC<SliderFunctionProp> = props => {
  return (
    <div className={"max-width"} style={props.disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
      <GelBoxLayout space="auto" distribution="spaceBetween">
        <GelParagraph>
          <span className={"slider-description"}>{props.minRangeLabel}</span>
        </GelParagraph>
        <GelParagraph>
          <span className={"slider-description"}>{props.maxRangeLabel}</span>
        </GelParagraph>
      </GelBoxLayout>
      <GelFormField label>
        <GelRadioGroup
          name={props.fieldName}
          value={props.formData[props.fieldName]} // has to be the same as `name`
          onChange={props.onFormDataChange}
          required
          requiredErrorMsg={customerQuestions.requiredQuestionMessage}
          buttonGroup
        >
          {choices.map((choice, index) => {
            return (
              <GelRadio key={index} value={choice.value}>
                {choice.name}
              </GelRadio>
            );
          })}
        </GelRadioGroup>
      </GelFormField>
    </div>
  );
};

export default FormSlider;
