import rules from "../assets/supportStreamRules.json";
import { CustomerQuestionsResult } from "../interfaces/CustomerQuestionsResult";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSupportStreamLabels = (state: CustomerQuestionsResult) => {
  //transform object string to lower to match ruleset
  if (state) {
    const stateTransformedToLower = Object.fromEntries(
      Object.entries(state).map(([k, v]) => (v != null && (typeof v === "string" || v instanceof String) ? [k, v.toLowerCase()] : [k, v])),
    );
    return engine(rules, stateTransformedToLower as CustomerQuestionsResult);
  }
  return "";
};

// eslint-disable-next-line
const engine = (rules: any[], state: CustomerQuestionsResult) => {
  const entry = rules.find(e => e.entry);
  let current = entry;
  // eslint-disable-next-line prefer-const
  let results = [];
  while (current?.condition) {
    current = rules.find(e => e.id == current["link-" + eval(current.condition)]);
    if (current?.output) results.push(current.output);
  }
  return results;
};
