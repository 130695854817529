import { syncSupportObject } from "../models/adobeDLTypes";
import { SupportGroup } from "../models/supportTypes";
import { fund } from "../models/claimDetailItems";

export const formatDate = (date: number): string => {
  const d = new Date(date),
    year = d.getFullYear();
  let month = "" + (d.getMonth() + 1),
    day = "" + d.getDate();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatSupportOptionsForAdobe = (supportGroups: SupportGroup[]): syncSupportObject[] => {
  const supportObject: syncSupportObject[] = [];
  supportGroups?.map(supportGroup => {
    supportGroup.supportServices?.map(supportService => {
      supportObject.push({
        option: supportService.title,
        checked: supportService.isSelected,
      });
    });
  });
  return supportObject;
};

/**
 * Searches for the key in the keyvalue store of funds
 * @param fundValue a string number from the key value store of funds
 * @returns null if no fund is found or a string with the name of the fund
 */
export function getAdobeFundName(fundValue: string): string | undefined {
  const fundName = fund.filter(v => v.value === fundValue).pop();

  if (fundName !== undefined) {
    return fundName.name;
  }

  return undefined;
}
