import React from "react";
import { SupportOptionProp } from "../../interfaces/props/SupportOptionProp";
import SupportGroupView from "./supportGroupView";

const SupportOptions: React.FC<SupportOptionProp> = props => {
  const supportGroups = props.supportGroups;

  return (
    <>
      {supportGroups.map(sg => (
        <div style={{ borderStyle: "solid", borderColor: "#CCCCCC", padding: "15px", margin: "20px" }} key={sg.id}>
          <SupportGroupView key={sg.id} supportGroup={sg} />
        </div>
      ))}
    </>
  );
};

export default SupportOptions;
