import React from "react";
import { GelIcon, GelCaption, GelContainerLite, GelBoxLayout } from "@tal-gel/components";
import "./footer.css";

const Footer: React.FC = () => {
  return (
    <GelContainerLite gutter="large" className={"footer"}>
      <GelBoxLayout
        space={{ xs: "auto", sm: "auto", md: [1, "auto"] }}
        alignment="start"
        distribution="start"
        gap="large"
        style={{ width: "100%" }}
      >
        <a href={"https://www.tal.com.au"} rel={"noopener noreferrer"} target="_blank">
          <img src={process.env.PUBLIC_URL + "/TAL.svg"} width="80px" alt={"TAL Logo"} />
        </a>
        <GelCaption>
          <span className="caption-color">
            2020 © TAL Services Limited ABN 60 076 105 130 a{" "}
            <a className={"footer-link"} href={"https://www.dai-ichi-life-hd.com/en/"} rel={"noopener noreferrer"} target="_blank">
              Dai-ichi Life Group
              <GelIcon name="ExternalLink" width={18} height={18} color="#80C342" />
            </a>{" "}
            Company{" "}
          </span>
        </GelCaption>
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export default Footer;
