import React from "react";
import {
  GelParagraph,
  GelForm,
  GelFormField,
  GelLabel,
  GelTextArea,
  GelRowLayout,
  GelBoxLayout,
  GelIcon,
  GelButton,
  useGelFormData,
} from "@tal-gel/components";
import "./customerQuestions.css";
import { CustomerQuestionsProp } from "../../interfaces/props/CustomerQuestionsProp";
import { customerQuestions } from "../../models/customerQuestionItems";
import FormSlider from "../formSlider/formSlider";
import quote from "../../assets/quote.svg";
import { CustomerQuestionsResult } from "../../interfaces/CustomerQuestionsResult";
import FormRadioButtonList from "../formRadioButton/formRadioButtonList";

const CustomerQuestions: React.FC<CustomerQuestionsProp> = props => {
  const selectedClaimType = props.selectedClaimType;
  const disabled = props.disabled;

  const Submit = () => {
    if (
      formData.homeActivities &&
      formData.leisureActivities &&
      formData.socialActivities &&
      formData.conditionActivities &&
      formData.improveActivities &&
      formData.professionalSupport &&
      formData.personalSupport &&
      formData.selectedRTW &&
      formData.finances &&
      formData.friendsFamilySupport
    ) {
      const questions: CustomerQuestionsResult = {
        homeActivities: Number(formData.homeActivities),
        leisureActivities: Number(formData.leisureActivities),
        socialActivities: Number(formData.socialActivities),
        conditionActivities: formData.conditionActivities,
        improveActivities: formData.improveActivities,
        professionalSupport: formData.professionalSupport,
        personalSupport: formData.personalSupport,
        rtw: formData.selectedRTW,
        finances: Number(formData.finances),
        friendsFamilySupport: formData.friendsFamilySupport,
      };
      //first check
      if (selectedClaimType == "TPD" && formData.selectedRTW == "Not expected to RTW" && formData.friendsFamilySupport == "No") {
        formData.rtwFollowUp = null;
        formData.friendsFamilyFollowUp = "";
      }
      //10
      if (selectedClaimType == "IP" && formData.selectedRTW == "Not expected to RTW") {
        formData.rtwFollowUp = null;
        if (formData.rtwLimitations) {
          questions.rtwLimitations = formData.rtwLimitations;
          formData.rtwConfidence = null;
        }
      }
      //10.1
      if (formData.rtwFollowUp) {
        questions.rtwFollowUp = formData.rtwFollowUp;
        if (formData.rtwFollowUp == "No") {
          if (formData.rtwLimitations) {
            questions.rtwLimitations = formData.rtwLimitations;
            formData.rtwConfidence = null;
            formData.rtwTimeframe = null;
          }
        } else {
          if (formData.rtwTimeframe) {
            questions.rtwTimeFrame = formData.rtwTimeframe;
            if (formData.rtwTimeframe !== "6+ months or Unsure") {
              if (formData.rtwConfidence) {
                questions.rtwConfidence = Number(formData.rtwConfidence);
                formData.rtwLimitations = "";
              }
            } else {
              if (formData.rtwLimitations && formData.rtwConfidence) {
                questions.rtwConfidence = Number(formData.rtwConfidence);
                questions.rtwLimitations = formData.rtwLimitations;
              }
            }
          }
        }
      }

      if (formData.friendsFamilySupport == "Yes") {
        if (formData.friendsFamilyFollowUp) {
          questions.friendsFamilyFollowUp = formData.friendsFamilyFollowUp;
        }
      }
      props.viewSupportOptions(questions);
    }
  };

  //Function to update values. NOTE:Sliders don't need this
  const UpdateRTW = (option: string) => {
    if (option === "Not expected to RTW") {
      formData.rtwFollowUp = null;
    }
  };

  // Use this to manage form data
  const { formData, onFormDataChange } = useGelFormData({
    homeActivities: null,
    leisureActivities: null,
    socialActivities: null,
    conditionActivities: "",
    improveActivities: "",
    professionalSupport: "",
    personalSupport: "",
    finances: null,
    friendsFamilySupport: null,
    friendsFamilyFollowUp: "",
    selectedRTW: null,
    rtwFollowUp: null,
    rtwConfidence: null,
    rtwTimeframe: null,
    rtwLimitations: "",
  });

  return (
    <GelRowLayout gutter={"medium"}>
      <div>
        <GelLabel>Instructions</GelLabel>
        <GelParagraph>
          <span className={"blackText"}>
            This tool provides a consistent way to track and talk to our customers and members about their need for support during claim
            time. For this reason, please follow the script as provided, only deviating slightly in order to help the conversation feel
            natural.
          </span>
        </GelParagraph>
      </div>
      <div className={"suicideWarning"}>
        <GelBoxLayout space={["auto", 1]} distribution="start">
          <GelIcon name="AlertCircle" width={24} color={"#fff"} />
          <GelParagraph>
            <span className={"whiteText"}>
              Monitor for indicators of suicide risk in conversation. Refer to TAL&apos;s guidelines for dealing with potential suicide on
              Claims Knowledge base
            </span>
          </GelParagraph>
        </GelBoxLayout>
      </div>
      <div className={"greenQuote"}>
        <GelBoxLayout space={["auto", 1]} distribution={"start"}>
          <img alt="quote mark" src={quote} className={"quote"} />
          <GelRowLayout gutter="medium">
            <GelParagraph>
              <span className={"blackText"}>
                During this part of our discussion I would like to take you through some questions to understand how your medical condition
                is impacting your normal activities. You are not required to answer these questions for us to assess your claim. These
                questions are designed to see whether there are further things we can do down the track to support you. Are you happy to
                proceed with answering these questions?
              </span>
            </GelParagraph>
            <GelParagraph>
              <span className={"blackText"}>
                These questions are on a scale from 1 to 10. 1 means you&apos;re unable or have low confidence to do a task and 10 means
                you&apos;re having no problems or have high confidence in doing the task.
              </span>
            </GelParagraph>
          </GelRowLayout>
        </GelBoxLayout>
      </div>
      <GelForm id={"form"} labelWidth={180} fieldGutter={30} labelAtTop onSubmit={Submit}>
        <GelRowLayout gutter={"large"}>
          <GelRowLayout gutter={"small"}>
            <GelLabel>{customerQuestions.homeActivities}</GelLabel>
            <FormSlider
              choiceNumber={1}
              disabled={disabled}
              minRangeLabel="Unable to do the activity"
              maxRangeLabel="Very easy"
              fieldName="homeActivities"
              formData={formData}
              onFormDataChange={onFormDataChange}
            />
          </GelRowLayout>
          <GelRowLayout gutter={"small"}>
            <GelLabel>{customerQuestions.leisureActivities}</GelLabel>
            <FormSlider
              choiceNumber={2}
              disabled={disabled}
              minRangeLabel="Unable to do the activity"
              maxRangeLabel="Very easy"
              fieldName="leisureActivities"
              formData={formData}
              onFormDataChange={onFormDataChange}
            />
          </GelRowLayout>
          <GelRowLayout gutter={"small"}>
            <GelLabel>{customerQuestions.socialActivities}</GelLabel>
            <FormSlider
              choiceNumber={3}
              disabled={disabled}
              minRangeLabel="Unable to do the activity"
              maxRangeLabel="Very easy"
              fieldName="socialActivities"
              formData={formData}
              onFormDataChange={onFormDataChange}
            />
          </GelRowLayout>
          <GelFormField label={customerQuestions.conditionActivities}>
            <GelTextArea
              required
              requiredErrorMsg={customerQuestions.requiredQuestionMessage}
              rows={4}
              disabled={disabled}
              name="conditionActivities"
              value={formData.conditionActivities}
              onChange={onFormDataChange}
            />
          </GelFormField>
          <GelFormField label={customerQuestions.improveActivities}>
            <GelTextArea
              required
              requiredErrorMsg={customerQuestions.requiredQuestionMessage}
              rows={4}
              disabled={disabled}
              name="improveActivities"
              value={formData.improveActivities}
              onChange={onFormDataChange}
            />
          </GelFormField>
          <GelFormField label={customerQuestions.professionalSupport}>
            <GelTextArea
              required
              requiredErrorMsg={customerQuestions.requiredQuestionMessage}
              rows={4}
              disabled={disabled}
              name="professionalSupport"
              value={formData.professionalSupport}
              onChange={onFormDataChange}
            />
          </GelFormField>
          <GelFormField label={customerQuestions.personalSupport}>
            <GelTextArea
              required
              requiredErrorMsg={customerQuestions.requiredQuestionMessage}
              rows={4}
              disabled={disabled}
              name="personalSupport"
              value={formData.personalSupport}
              onChange={onFormDataChange}
            />
          </GelFormField>
          <FormRadioButtonList
            label={customerQuestions.rtw}
            disabled={disabled}
            update={UpdateRTW}
            fieldName="selectedRTW"
            formData={formData}
            onFormDataChange={onFormDataChange}
            choices={[
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" },
              { value: "Not expected to RTW", text: "Not expected to RTW" },
            ]}
          />
          {(selectedClaimType == "IP" || selectedClaimType == "TPD") &&
          !!formData.selectedRTW &&
          formData.selectedRTW !== "Not expected to RTW" ? (
            <FormRadioButtonList
              label={customerQuestions.rtwFollowUp}
              disabled={disabled}
              fieldName="rtwFollowUp"
              formData={formData}
              onFormDataChange={onFormDataChange}
              choices={[
                { value: "Yes", text: "Yes" },
                { value: "No", text: "No" },
              ]}
            />
          ) : null}
          {(selectedClaimType == "IP" || selectedClaimType == "TPD") && !!formData.rtwFollowUp && formData.rtwFollowUp == "Yes" ? (
            <FormRadioButtonList
              label={customerQuestions.rtwTimeFrame}
              disabled={disabled}
              fieldName="rtwTimeframe"
              formData={formData}
              onFormDataChange={onFormDataChange}
              choices={[
                { value: "At Work or < 3 months", text: "At Work or < 3 months" },
                { value: "3-6 months", text: "3-6 months" },
                { value: "6+ months or Unsure", text: "6+ months or Unsure" },
              ]}
            />
          ) : null}
          {(selectedClaimType == "IP" || selectedClaimType == "TPD") &&
          !!formData.rtwFollowUp &&
          formData.rtwFollowUp == "Yes" &&
          !!formData.rtwTimeframe &&
          formData.selectedRTW !== "Not expected to RTW" ? (
            <GelRowLayout gutter={"small"}>
              <GelLabel>{customerQuestions.rtwConfidence}</GelLabel>
              <FormSlider
                choiceNumber={4}
                disabled={disabled}
                minRangeLabel="Very low confidence"
                maxRangeLabel="Very high confidence"
                fieldName="rtwConfidence"
                formData={formData}
                onFormDataChange={onFormDataChange}
              />
            </GelRowLayout>
          ) : null}
          {(selectedClaimType == "IP" && formData.selectedRTW == "Not expected to RTW") ||
          ((selectedClaimType == "IP" || selectedClaimType == "TPD") &&
            (formData.rtwFollowUp == "No" || (formData.rtwFollowUp == "Yes" && formData.rtwTimeframe == "6+ months or Unsure"))) ? (
            <GelFormField label={customerQuestions.rtwLimitations} disabled={disabled}>
              <GelTextArea
                required
                requiredErrorMsg={customerQuestions.requiredQuestionMessage}
                rows={5}
                name="rtwLimitations"
                value={formData.rtwLimitations}
                onChange={onFormDataChange}
              />
            </GelFormField>
          ) : null}
          <GelRowLayout gutter={"small"}>
            <GelLabel>{customerQuestions.finances}</GelLabel>
            <FormSlider
              choiceNumber={5}
              disabled={disabled}
              minRangeLabel="Very low confidence"
              maxRangeLabel="Very high confidence"
              fieldName="finances"
              formData={formData}
              onFormDataChange={onFormDataChange}
            />
          </GelRowLayout>
          <FormRadioButtonList
            label={customerQuestions.friendsFamilySupport}
            disabled={disabled}
            fieldName="friendsFamilySupport"
            formData={formData}
            onFormDataChange={onFormDataChange}
            choices={[
              { value: "Yes", text: "Yes" },
              { value: "No", text: "No" },
            ]}
          />
          {formData.friendsFamilySupport == "Yes" ? (
            <GelFormField label={customerQuestions.friendsFamilyFollowUp}>
              <GelTextArea
                required
                requiredErrorMsg={customerQuestions.requiredQuestionMessage}
                rows={5}
                disabled={disabled}
                name="friendsFamilyFollowUp"
                value={formData.friendsFamilyFollowUp}
                onChange={onFormDataChange}
              />
            </GelFormField>
          ) : null}
          <GelBoxLayout space="auto" distribution="start">
            <GelFormField>
              <GelButton
                name="viewSupportOptionsButton"
                primary
                disabled={props.enableSupportButton === undefined ? false : !props.enableSupportButton}
                medium
                submit
              >
                VIEW SUPPORT OPTIONS
              </GelButton>
            </GelFormField>
            <GelButton primary disabled={!props.enableEditAnswerButton} medium onClick={props.editAnswers}>
              EDIT ANSWERS
            </GelButton>
          </GelBoxLayout>
        </GelRowLayout>
      </GelForm>
    </GelRowLayout>
  );
};

export default CustomerQuestions;
