export interface CustomerQuestionsResult {
  homeActivities: number;
  leisureActivities: number;
  socialActivities: number;
  conditionActivities: string;
  improveActivities: string;
  professionalSupport: string;
  personalSupport: string;
  rtw: string;
  rtwFollowUp?: string;
  rtwConfidence?: number;
  rtwTimeFrame?: string;
  rtwLimitations?: string;
  finances: number;
  friendsFamilySupport: string;
  friendsFamilyFollowUp?: string;
}

/**
 * Used for iterating over values in the @type {CustomerQuestionsResult} and @see {customerQuestionItems}
 * inside of @see {CopyToClipboard} please keep keys aligned
 */
export const CustomerQuestionsResultKeys = [
  "homeActivities",
  "leisureActivities",
  "socialActivities",
  "conditionActivities",
  "improveActivities",
  "professionalSupport",
  "personalSupport",
  "rtw",
  "rtwFollowUp",
  "rtwConfidence",
  "rtwTimeframe",
  "rtwLimitations",
  "finances",
  "friendsFamilySupport",
  "friendsFamilyFollowUp",
];
