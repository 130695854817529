import React, { useState } from "react";
import { GelRowLayout, GelParagraph, GelIcon, GelLabel, GelBoxLayout, GelTooltip } from "@tal-gel/components";
import { SupportGroup } from "../../models/supportTypes";
import SupportServiceView from "./supportServiceView";

import "./supportGroupView.css";

interface propsFromComponent {
  supportGroup: SupportGroup;
}

type Props = propsFromComponent;

const SupportGroupView: React.FC<Props> = ({ supportGroup }) => {
  const [showSupportServices, setShowSupportServices] = useState<boolean>(false);

  return (
    <GelRowLayout gutter={"small"}>
      {supportGroup.title && (
        <GelBoxLayout space={[1, "auto"]} gap="small" distribution="spaceBetween">
          <GelBoxLayout space={["auto", 1]} gap="small" distribution="start">
            <GelLabel className={"labelText"}>{supportGroup.title}</GelLabel>
            <GelTooltip topRight>Confirm which of these areas and services is of interest</GelTooltip>
          </GelBoxLayout>
          {supportGroup.supportServices?.length > 1 && (
            <GelIcon
              name={showSupportServices ? "Minus" : "Plus"}
              width={25}
              color={"#80C342"}
              onClick={() => setShowSupportServices(!showSupportServices)}
            />
          )}
        </GelBoxLayout>
      )}
      {supportGroup.description && <GelParagraph>{supportGroup.description}</GelParagraph>}
      {supportGroup.supportServices.map(s => (
        <GelRowLayout
          gutter="small"
          key={s.id}
          style={{
            padding: "15px",
            display: showSupportServices || !supportGroup.title || supportGroup.supportServices.length === 1 ? "block" : "none",
          }}
        >
          <SupportServiceView supportService={s} />
        </GelRowLayout>
      ))}
    </GelRowLayout>
  );
};

export default SupportGroupView;
