const oktaAuthConfig = {
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENTID,
  redirectUri: process.env.REACT_APP_OKTA_APP_BASE_URL + "/login/callback",
  postLogoutRedirectUri: process.env.REACT_APP_OKTA_APP_BASE_URL,
  authParams: {
    issuer: `${process.env.REACT_APP_OKTA_ISSUER}/oauth2/v1/authorize`,
  },
};

const oktaSignInConfig = {
  baseUrl: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENTID,
  redirectUri: process.env.REACT_APP_OKTA_APP_BASE_URL + "/login/callback",
  postLogoutRedirectUri: process.env.REACT_APP_OKTA_APP_BASE_URL,
  authParams: {
    issuer: `${process.env.REACT_APP_OKTA_ISSUER}/oauth2/v1/authorize`,
  },
};

export { oktaAuthConfig, oktaSignInConfig };
