import React from "react";

const PageNotFound = (): JSX.Element => (
  <div className="main-container">
    <h2>Urgh...</h2>
    <p>You are lost in the clouds...</p>
  </div>
);

export default PageNotFound;
