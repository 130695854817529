import React, { useState } from "react";
import { GelRowLayout, GelParagraph, GelCheckbox, GelHeading5 } from "@tal-gel/components";
import { SupportService } from "../../models/supportTypes";

interface propsFromComponent {
  supportService: SupportService;
}

type Props = propsFromComponent;

const SupportServiceView: React.FC<Props> = ({ supportService }) => {
  const [isSelected, setIsSelected] = useState(false);
  const ChangeSelection = () => {
    setIsSelected(!isSelected);
    supportService.isSelected = !isSelected;
  };
  return (
    <GelRowLayout gutter={"small"}>
      <GelCheckbox
        checked={isSelected}
        onChange={() => {
          ChangeSelection();
        }}
      >
        <GelHeading5>{supportService.title}</GelHeading5>
      </GelCheckbox>
      <GelParagraph style={{ paddingLeft: "40px" }}>{supportService.description}</GelParagraph>
    </GelRowLayout>
  );
};

export default SupportServiceView;
