export enum CallerType {
  CLAIMANT = "1",
  CARER = "2",
}

export enum FriendsFamilySupport {
  YES = "Yes",
  NO = "No",
}

export enum Funds {
  AwareSuper = "1",
  AustralianSuper = "2",
}

export enum ClaimStatusDescriptions {
  Notified = "notified",
  Received = "received",
  Admitted = "admitted",
  Closed = "closed",
}

export enum Condition {
  Cancer = "1",
  MentalAndBehaviouralDisorders = "2",
  MusculoskeletalInclInjuriesAndFractures = "3",
  Other = "4",
}

export enum Employment {
  YesEmployed = "1",
  YesSelfEmployed = "2",
  No = "3",
}

export enum ClaimType {
  TPD = "TPD",
  IP = "IP",
}
