import { ClaimDetailsResult } from "../interfaces/ClaimDetailsResult";
import { CustomerQuestionsResult, CustomerQuestionsResultKeys } from "../interfaces/CustomerQuestionsResult";
import { claimStatus, claimTypes, employment, fund, caller, condition } from "../models/claimDetailItems";
import { customerQuestions } from "../models/customerQuestionItems";
import { SupportGroup, SupportService } from "../models/supportTypes";

const newLine = "\n";
const newLineEmail = "%0A";
const tab = "\t";
const newLineFollowedByTab = `${newLine}${tab}`;
const selected = `[X]${tab}`;
const notSelected = `[ ]${tab}`;
const answerLabel = "Response:";
const noAnswer = "No response provided";
const emailSubject = "TAL Health Support";
const emailBodyStaticHeader = `Dear <Member name>,${newLine}${newLine}Thank you for taking time to speak with me today.${newLine}${newLine}Following our conversation, there are a range of support services available that may complement support services already available to you. Everyone’s health journey and needs are different, so I’ve included information about support services that may be relevant for you to review in your own time.${newLine}${newLine}Once you have had a chance to review these, we can speak further about services that are relevant to you.${newLine}`;
const emailBodyStaticFooter = `${newLine}${newLine}Your support needs may change over time and there may be other support options available to you. If you would like to discuss the support available to you in more detail, I will speak with my Recovery and Support Specialist. They will help to identify support options suitable for your circumstances.${newLine}${newLine}Kind Regards,${newLine}${newLine}`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CopyToClipboard = async (
  claimDetailsResult: ClaimDetailsResult,
  customerQuestionResult: CustomerQuestionsResult,
  supportStreamLabel: string,
  supportGroups: SupportGroup[],
) => {
  if (!claimDetailsResult) return false;
  try {
    const customerResponse = getCustomerResponseString(customerQuestionResult);
    // eslint-disable-next-line
    const resp = await navigator.clipboard.writeText(
      customerResponse !== ""
        ? `${getClaimDetailsString(claimDetailsResult)}${newLine}${newLine}${getCustomerResponseString(
            customerQuestionResult,
          )}${getSupportStreamText(supportStreamLabel)}${newLine}${newLine}${
            supportGroups?.length > 0 ? getSupportOptionsString(supportGroups) : null
          }`
        : `${getClaimDetailsString(claimDetailsResult)}${getSupportStreamText(supportStreamLabel)}${newLine}${newLine}${
            supportGroups?.length > 0 ? getSupportOptionsString(supportGroups) : null
          }`,
    );
    return true;
  } catch (error) {
    console.log(`Error with Copy to File Note - ${error}`);
    return false;
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CopyToEmail = async (
  claimDetailsResult: ClaimDetailsResult,
  _customerQuestionResult: CustomerQuestionsResult,
  _supportStreamLabel: string,
  supportGroups: SupportGroup[],
) => {
  if (!claimDetailsResult) return false;
  try {
    // eslint-disable-next-line
    const emailBody = `${supportGroups?.length > 0 ? getSupportOptionsStringForEmail(supportGroups) : null}`;
    window.location.href = `mailto:?subject=${emailSubject}`;
    const resp = await navigator.clipboard.writeText(`${emailBodyStaticHeader}${emailBody}${emailBodyStaticFooter}`);
    return true;
  } catch (error) {
    console.log(`Error with Copy to Email - ${error}`);
    return false;
  }
};

const getClaimDetailsString = (claimDetailsResult: ClaimDetailsResult): string => {
  const claimDetailsResultResolved = resolveClaimsDetails(claimDetailsResult);
  return `Claim Information ${newLine}${newLineFollowedByTab}Date Completed: ${
    claimDetailsResultResolved.date
  }${newLineFollowedByTab}Claim No: ${claimDetailsResultResolved.claimNumber}${newLineFollowedByTab}Claim Type: ${
    claimDetailsResultResolved.claimType
  }${newLineFollowedByTab}Claim Status: ${claimDetailsResultResolved.claimStatus}${newLineFollowedByTab}Condition: ${
    claimDetailsResultResolved.condition
  }${newLineFollowedByTab}Fund: ${claimDetailsResultResolved.fund}${newLineFollowedByTab}Job Available: ${
    claimDetailsResultResolved.jobAvailable ? claimDetailsResultResolved.jobAvailable : noAnswer
  }${newLineFollowedByTab}Caller: ${claimDetailsResultResolved.caller}`;
};

const resolveClaimsDetails = (claimDetailsResult: ClaimDetailsResult): ClaimDetailsResult => {
  return {
    date: claimDetailsResult.date,
    claimNumber: claimDetailsResult.claimNumber,
    claimType: claimTypes.filter(claimType => claimType.value === claimDetailsResult.claimType)[0].name,
    claimStatus: claimStatus.filter(claimStat => claimStat.value === claimDetailsResult.claimStatus)[0].name,
    condition: condition.filter(cond => cond.value === claimDetailsResult.condition)[0].name,
    fund: fund.filter(f => f.value === claimDetailsResult.fund)[0].name,
    jobAvailable: employment.filter(emp => emp.value === claimDetailsResult.jobAvailable)[0].name,
    caller: caller.filter(call => call.value === Number(claimDetailsResult.caller))[0].name,
  };
};

const getCustomerResponseString = (customerQuestionResults: CustomerQuestionsResult): string => {
  if (!customerQuestionResults) {
    return "";
  }

  const customerQuestionString = CustomerQuestionsResultKeys.map(v => {
    const cqKey: keyof typeof customerQuestions = v as keyof typeof customerQuestions;
    const cqrKey: keyof typeof customerQuestionResults = v as keyof typeof customerQuestionResults;
    return getQuestionResponseText(customerQuestions[cqKey], customerQuestionResults[cqrKey]);
  });

  return `Customer Questions ${newLine}${customerQuestionString.join("")}`;
};

const getSupportStreamText = (supportStream: string): string => {
  if (supportStream?.length < 1) return "";
  return `${newLine}${newLine}Support Stream: ${supportStream}`;
};
// eslint-disable-next-line
const getQuestionResponseText = (question: string, response: any): string => {
  if (!response) return "";
  return `${newLineFollowedByTab}${question} ${newLineFollowedByTab}${answerLabel} ${response} ${newLine}${newLine}`;
};

const getSupportOptionsString = (supportGroups: SupportGroup[]): string => {
  if (!supportGroups || supportGroups?.length < 1) return "";
  return `Support Options ${newLine} ${supportGroups.map(supportGroup => {
    return getSupportGroupText(supportGroup);
  })}`;
};

const getSupportGroupText = (supportGroup: SupportGroup): string => {
  if (!supportGroup) return "";
  if (supportGroup.title && supportGroup.description) {
    return `${newLine}${newLineFollowedByTab}${supportGroup.title}${newLine}${newLineFollowedByTab}${
      supportGroup.description
    }${getSupportServicesText(supportGroup.supportServices)}`;
  }
  if (supportGroup.supportServices.length > 0) return getSupportServicesText(supportGroup.supportServices);
  return "";
};

const getSupportServicesText = (supportServices: SupportService[]): string => {
  if (!supportServices || supportServices.length < 1) return "";
  return `${supportServices.map(supportService => {
    return getSupportServiceText(supportService);
  })}`;
};

const getSupportServiceText = (supportService: SupportService): string => {
  if (!supportService) return "";
  if (supportService.isSelected)
    return `${newLine}${newLineFollowedByTab}${selected}${supportService.title}${newLine}${newLineFollowedByTab}${tab}${supportService.description}`;
  return `${newLine}${newLineFollowedByTab}${notSelected}${supportService.title}${newLine}${newLineFollowedByTab}${tab}${supportService.description}`;
};

//Email related functions
const getSupportOptionsStringForEmail = (supportGroups: SupportGroup[]): string => {
  if (!supportGroups || supportGroups?.length < 1) return "";
  return `${supportGroups
    .map(supportGroup => {
      if (!supportGroup.supportServices || supportGroup.supportServices.length < 1) return "";
      return getSupportServicesTextForEmail(supportGroup.supportServices);
    })
    .join(`${newLine}`)}`;
};

const getSupportServicesTextForEmail = (supportServices: SupportService[]): string => {
  return supportServices
    .map(supportService => {
      return `${newLine}${getSupportServiceTextForEmail(supportService)}`;
    })
    .join(`${newLine}`);
};

const getSupportServiceTextForEmail = (supportService: SupportService): string => {
  if (!supportService) return "";
  return `${supportService.title}: ${supportService.shortDescription} ${supportService.link}`;
};
