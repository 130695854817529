import { KeyObject } from "../interfaces/KeyValue";
import { getGelTokens } from "@tal-gel/theming";

export const SupportStreamCollection: KeyObject[] = [
  { value: "ASSIST_PLUS", obj: { text: "Assist Plus", color: getGelTokens().brand.brandColorSecondary4 } },
  { value: "ASSIST", obj: { text: "Assist", color: getGelTokens().brand.brandColorPrimary2 } },
  { value: "SUPPORT", obj: { text: "Support", color: getGelTokens().brand.brandColorSecondary1 } },
  { value: "FAST_TRACK", obj: { text: "Fast Track", color: getGelTokens().brand.brandColorPrimary1 } },
  { value: "RTW_NOT_EXPECTED", obj: { text: "RTW Not Expected", color: getGelTokens().brand.brandColorPrimary2 } },
];
