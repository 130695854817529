import React from "react";
import "./header.css";
import { GelContainerLite, GelBoxLayout } from "@tal-gel/components";

const PublicHeader: React.FC = () => {
  return (
    <GelContainerLite
      gutter="medium"
      style={{
        borderBottom: "solid 1px #ccc",
      }}
    >
      <GelBoxLayout space={"auto"} distribution={"spaceBetween"}>
        <GelBoxLayout space="auto" distribution="start" gap="large">
          <a className={"icon-link"} href={"/"}>
            <GelBoxLayout space="auto" distribution="start" gap="small">
              <img src={process.env.PUBLIC_URL + "/TAL-header.svg"} width="80px" alt={"TAL Logo"} />
              <strong className="title">
                SUPPORT
                <br />
                INDICATOR
              </strong>
            </GelBoxLayout>
          </a>
        </GelBoxLayout>
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export default PublicHeader;
