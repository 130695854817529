import React from "react";
import { Route, Switch } from "react-router-dom";

import { GelThemeProvider } from "@tal-gel/theming";
import {
  GelScreenDetector,
  GelPageLayout,
  GelPageHeader,
  GelContainerLite,
  GelPageContent,
  GelPageFooter,
  GelRowLayout,
} from "@tal-gel/components";

import "../App.css";
import Footer from "../components/footer/footer";
import Form from "../pages/claimantForm/form";

import PublicHeader from "../components/header/publicHeader";
import Home from "../pages/home/home";
import SignOut from "../pages/signout/signout";
import PageNotFound from "../pages/pageNotFound";
import { LoginCallback, SecureRoute, useOktaAuth } from "@okta/okta-react";
import Header from "../components/header/header";
import Login from "../pages/signin/login";

const AuthLayout = (): JSX.Element => {
  const { authState } = useOktaAuth();

  return (
    <GelThemeProvider>
      <GelScreenDetector>
        <GelPageLayout>
          <GelPageHeader>{authState?.isAuthenticated ? <Header /> : <PublicHeader />}</GelPageHeader>
          <GelPageContent fixed>
            <GelContainerLite
              style={{
                backgroundColor: "#fff",
                height: "100%",
                paddingBottom: "1em",
                margin: "auto",
                alignContent: "center",
              }}
            >
              <Switch>
                <SecureRoute path="/me/form" component={Form} />
                <SecureRoute path="/me/sign-out" component={SignOut} />
                <Route path="/login/callback" component={LoginCallback} />
                <Route path="/login" component={Login} />
                <Route path="" exact={true} component={Home} />
                <Route component={PageNotFound} />
              </Switch>
            </GelContainerLite>
          </GelPageContent>
          <GelPageFooter>
            <GelRowLayout alignment={"center"} style={{ backgroundColor: "#222" }}>
              <Footer />
            </GelRowLayout>
          </GelPageFooter>
        </GelPageLayout>
      </GelScreenDetector>
    </GelThemeProvider>
  );
};

export default AuthLayout;
